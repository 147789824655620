import {Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input} from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { Subject, Observable } from 'rxjs';
import {Dialog} from "@a3l/utilities";


@Component({
  selector: 'a3l-ui-helper-button',
  templateUrl: './helper.button.html',
  styleUrls: ['./helper.button.scss'],
})
export class HelperButton {

  @Input()
  dialogClass: any;

  @Input()
  tooltipInfo: any;

  @Input()
  absolute = false;

  @Input()
  iconClass = null;

  constructor(private dialog: Dialog) {
  }

  openDialog(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(this.dialogClass)
  }
}
