import {
  AfterViewInit,
  Component,
  forwardRef, Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';


import {Control} from '@a3l/utilities';

import {Dispatcher} from "@a3l/core";
import {WorkExperienceForm} from "@rex/common/work-experiences/work-experience.form";
import {CvParserCandidateCompaniesEvent} from "@rex/shared/recruitments/events/cv-parser-candidate-companies.event";
import {
  LocalizedContentFieldCreator
} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content-field-creator";
import {LocalizedContent} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content";
import {
  LocalizedContentField
} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content-field";
import {TranslateService} from "@ngx-translate/core";
import { toLocalized } from '../../../../../../libs/utilities/src/lib/form/localized-content/toLocalized';

@Component({
  selector: 'rex-work-experiences-field',
  templateUrl: './work-experiences.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WorkExperiencesField),
      multi: true,
    },
    { provide: Control, useExisting: WorkExperiencesField },
  ],
  styleUrls: ['../form-accordion.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkExperiencesField extends Control implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
  @Input() activeLanguage: string;
  @Input() validateExperience: Subject<boolean>;

  currentIndex = [];
  isValid = true;
  isLoading = false;

  @ViewChild('accordion') accordion;

  /**
   * @var {any}
   */
  value: any = [];

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  constructor(
    private dispatcher: Dispatcher,
    private translator: TranslateService,
    private workExperienceItemForm: WorkExperienceForm
  ) {
    super();
  }

  addNew() {
    if (this.value.length) this.validate();

    if (!this.isValid) return;

    this.value.push(this.workExperienceItemForm.emptyForm());
    this.propagateChange(this.value)
    this.currentIndex.push(this.value.length - 1);
  }

  validate()
  {
    if (!this.value.length) {
      this.isValid = true;
      return;
    }

    this.value.forEach((formGroup: FormGroup, index) => {
      this.isValid = formGroup.validate();

      if (!this.isValid && !this.currentIndex.includes(index)) {
        this.currentIndex.push(index);

        if (this.accordion) {
          this.accordion.updateSelectionState();
        }
      }

      if (!this.isValid) return;
    });
  }

  removePosition(uuid) {
    this.value.splice(this.getItemIndexByUuid(uuid), 1);

    this.propagateChange(this.value);

    this.validate();

  }

  getItemIndexByUuid(uuid) {
    let foundIndex = undefined;
    this.value.forEach((item, index) => {
      if(item.controls['uuid'].value == uuid) {
        foundIndex = index;
      }
    });

    return foundIndex;
  }

  update($event) {
    this.value[this.getItemIndexByUuid($event.controls['uuid'].value)] = $event;
    this.propagateChange(this.value)
  }

  identify(index, item)
  {
    return item.controls['uuid'].value;
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.dispatcher.listen(CvParserCandidateCompaniesEvent).subscribe(({value, disabled}) => {
      if(value === null) {
        this.value = [];
      } else if(value) {
        value.forEach((experience) => {
          console.log(experience);
          experience.position_name = new LocalizedContent([new LocalizedContentField(this.translator.currentLang, experience.position_name)]);
          experience.industry = experience.industry;
          experience.level = experience.level;
          experience.to = experience.to === 'obecnie' ? 'Do dziś' : experience.to;
          experience.responsibilities = new LocalizedContent([new LocalizedContentField(this.translator.currentLang, experience.responsibilities)]);
          experience.original_responsibilities = new LocalizedContent([new LocalizedContentField(this.translator.currentLang, experience.original_responsibilities)]);
          this.value.push(this.workExperienceItemForm.formFromArray(experience));
        });
      }
      this.propagateChange(this.value);
      disabled ? this.isLoading = true : this.isLoading = false;
    });

    this.validateExperience.subscribe(() => {
      this.validate();
    });
  }

  ngAfterViewInit()
  {
    this.accordion.activeIndexChange.subscribe( (index) => this.currentIndex = index);
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    if (!value || value == undefined) {
      this.value = [];
      return;
    }
    if(value.length == 0) return;
    this.value = value
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Initialization.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected readonly toLocalized = toLocalized;
}
