import { Injectable } from '@angular/core';
import {finalize, switchMap} from 'rxjs/operators';

import { saveAs } from 'file-saver';

import { Command } from '@a3l/core';
import { ApiService } from '@a3l/api';

@Injectable({ providedIn: 'root' })
export class FileDownloadCommand extends Command<void> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   */
  constructor(private api: ApiService) {
    super();
  }

  /**
   * Run the command.
   *
   * @param {any} model
   * @return void
   */
  execute({ id, filename, preview}: { id: string | number; filename?: string, preview: boolean }): void {
    this._pending$.next(true);

    if(preview) {
      this.api
        .get(`/media/${id}/${filename}`, {'preview': true})
        .subscribe(( data ) => {
          window.open(data.url);

          this._complete$.next();
        });
    } else {
      this.api
        .get(`/media/${id}/${filename}`, {'preview': false}, { responseType: 'blob' as 'json', observe: 'response' })
        .pipe(finalize(() => this._pending$.next(false)))
        .subscribe(({ body: blob }) => {
          saveAs(blob, filename);

          this._complete$.next();
        });
    }

  }

  generateBlindCv(generateFile, preview): void {
    this._pending$.next(true);

    generateFile.preview = preview;

    if(preview) {
      this.api
        .get(`/generate-blind-cv`, generateFile)
        .subscribe(( data ) => {
          window.open(data.url);

          this._complete$.next();
        });
    } else {
      this.api
        .get(`/generate-blind-cv`, generateFile, { responseType: 'blob' as 'json', observe: 'response' })
        .pipe(finalize(() => this._pending$.next(false)))
        .subscribe(({ body: blob }) => {
          saveAs(blob, generateFile.file_name);

          this._complete$.next();
        });
    }

  }

  generateCandidateProfile(generateFile): void {
    this._pending$.next(true);

    generateFile.preview = false;

    this.api
      .get(`/generate-candidate-profile`, generateFile, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe(finalize(() => this._pending$.next(false)))
      .subscribe(({ body: blob }) => {
        saveAs(blob, generateFile.file_name);

        this._complete$.next();
      });
    }
}
