import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef, Input, ViewEncapsulation} from "@angular/core";
import {Control} from './control';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'a3l-ui-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
    { provide: Control, useExisting: AutocompleteComponent }
  ],
  host: {
    class: 'a3l-ui-autocomplete',
  },
  encapsulation: ViewEncapsulation.None,
})
export class AutocompleteComponent extends Control implements ControlValueAccessor {


  @Input()
  value: Array<string> = [];

  @Input()
  disableInput: boolean = false;

  @Input()
  forceSelection: boolean = false;

  /**
   * @var {string}
   */
  @Input()
  placeholder: string;

  /**
   * @var {any}
   */
  @Input()
  suggestions: any = [];

  filteredSuggestions: any[] | undefined;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};


  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = false;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;
    this.propagateChange(this.value);
  }

  updateValue(): void {
    this.propagateChange(this.value);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  filterSuggestions(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
      let suggestion = (this.suggestions as any[])[i];

      if (suggestion.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(suggestion);
      }
    }

    this.filteredSuggestions = filtered;
  }

  onKeyUp(event: KeyboardEvent) {
    if(this.disableInput) {
      return;
    }

    if (event.key == "Enter") {
      this.updateByInput(event.target);
    }
  }

  onBlur(event: FocusEvent) {
    if(this.disableInput) {
      return;
    }
    const suggestions = document.querySelectorAll(".p-autocomplete-panel ul li:hover");

    if (suggestions.length === 0) {
      this.updateByInput(event.target);
    }
  }

  updateByInput(tokenInput: any) {
    if (!this.value) {
      this.value = [];
    }

    if (tokenInput.value) {
      let cloneValue = JSON.parse(JSON.stringify(this.value));

      cloneValue.push(tokenInput.value);
      this.value = cloneValue
      this.writeValue(this.value);
      tokenInput.value = "";
    }
  }
}
