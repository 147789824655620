import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Injectable} from "@angular/core";
import {uuid} from "@a3l/utilities";
import {LocalizedContent} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content";
import {
  LocalizedContentField
} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content-field";
import {TranslateService} from "@ngx-translate/core";
import {
  LocalizedContentFieldCreator
} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content-field-creator";

@Injectable({providedIn: 'root'})
export class WorkExperienceForm {

  constructor(
    private translator: TranslateService
  ) {
  }

  public emptyForm = (params = null) => {
    return new FormGroup({
      uuid: new FormControl(uuid()),
      company_name: new FormControl(null, Validators.required),
      position_name: new FormControl(null, Validators.required),
      from: new FormControl(null),
      to: new FormControl(null),
      responsibilities: new FormControl(null),
      original_responsibilities: new FormControl(null),
      industry: new FormControl(null, Validators.required),
      level: new FormControl(null)
    });
  }

  public formFromArray = (array) => {
    let form = this.emptyForm(array.parameters);
    let position_name = new LocalizedContentFieldCreator(array.position_name).getArray();
    let responsibilities = new LocalizedContentFieldCreator(array.responsibilities).getArray();
    let original_responsibilities = new LocalizedContentFieldCreator(array.original_responsibilities).getArray();
    form.controls['uuid'].setValue(array.uuid ? array.uuid : uuid());
    form.controls['company_name'].setValue(array.company_name);
    form.controls['from'].setValue(array.from);
    form.controls['to'].setValue(array.to);
    form.controls['position_name'].setValue(position_name);
    form.controls['responsibilities'].setValue(responsibilities);
    form.controls['original_responsibilities'].setValue(original_responsibilities);
    form.controls['industry'].setValue(array.industry);
    form.controls['level'].setValue(array.level);

    return form;
  }

}
